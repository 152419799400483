import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Contact({children, desc}) {
  return (
    <div class="max-w-sm text-center m-6 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <h1 className='text-gray-400'>{children}</h1>
        <p class="mb-3 font-normal">{desc}</p>
     </div>
  )
}

export default Contact