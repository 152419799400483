import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import './App.css';
import Board from './Board';
import TopNav from './TopNav';
import Home from './Home';
import  SignIn from './Components/auth/SignIn';
import SignUp from './Components/auth/SignUp';
import PaymentResult from './Components/PaymentResult';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import AuthDetails from './Components/auth/AuthDetails';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import UserManagement from './Components/Admin/UserManagement';
import Help from './Components/Admin/Help';
import CreditCard from './Components/CreditCard';
import { getAuth } from 'firebase/auth';
import ForgotPassword from './Components/auth/ForgotPassword';
import VerifyEmail from './Components/VerifyEmail';
import Plans from './Plans';

const firebaseConfig = {
  apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
  authDomain: "propertydash-381e9.firebaseapp.com",
  projectId: "propertydash-381e9",
  storageBucket: "propertydash-381e9.appspot.com",
  messagingSenderId: "275281356817",
  appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
  measurementId: "G-T5KBRNMD8K"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" 
      element={
        <PrivateRoute isAllowed={!!auth.currentUser}>
          <Home/>
        </PrivateRoute>
      }/>
      <Route path="/signin" element={<SignIn/>}/>
      <Route path="/signup/:plan" element={<SignUp/>}/>
      <Route path="/plans" element={<Plans/>} />
      <Route path="/forgotpassword" element={<ForgotPassword/>}/>
      <Route path="/verifyemail" element={<VerifyEmail/>}/>
      <Route path="/signup/creditcard/:email/:plan" element={
            <PrivateRoute isAllowed={!!auth.currentUser}>
              <CreditCard/>
            </PrivateRoute>}/>
      <Route path="/signup/paymentresult/:email/:plan" element={
            <PrivateRoute isAllowed={!!auth.currentUser}>
              <PaymentResult/>
            </PrivateRoute>}/>
      <Route path="/admin/usermanagement" element={
            <PrivateRoute isAllowed={!!auth.currentUser}>
                <UserManagement/>
            </PrivateRoute>}/>
      <Route path="/admin/help" element={
            <PrivateRoute isAllowed={!!auth.currentUser}>
              <Help/>
            </PrivateRoute>}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
