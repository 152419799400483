import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faBath } from "@fortawesome/free-solid-svg-icons";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faTreeCity } from "@fortawesome/free-solid-svg-icons";
import NewPropertyModal from "./Components/NewPropertyModal";

function CardDate({label, date}) {
  if (date !== "") {
    return (
      <tr>
        <td>{label} {new Date(date).toDateString()}</td>
      </tr>
    );
  }
  else return "";
}

export default ({id, propertyInfo, index, onClicked}) => {
    return (
        <Draggable draggableId={id} key={id} index={index}>
          {(provided) => (
          <div className='property card mt-1' onClick={(() => {onClicked(id)})} {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
            <div className="card-body">
              <p className="card-title"><b>{propertyInfo.address}</b>
              </p>
            </div>
            {
              propertyInfo.tenanted &&
              <div className="ml-2">
                <span className="p-1 text-white text-sm font-semibold bg-red-600">TENANTED</span>
              </div>
            }
            <table className="table table-borderless">
              <tbody>
                <tr>
                  {propertyInfo.rent.value && <td>${propertyInfo.rent.value} {propertyInfo.rent.type}</td>}
                </tr>
                <CardDate label={"Available"} date={propertyInfo.availableDate}/>
                <CardDate label={"Open"} date={propertyInfo.openDate}/>
              </tbody>
            </table>
            <div className="card-footer">
              <span className="attribute-icon"> <FontAwesomeIcon icon={faBed} /></span>
              <span className="attribute">{propertyInfo.attributes.bed}</span>
              <span className="attribute-icon"> <FontAwesomeIcon icon={faBath} /></span>
              <span className="attribute">{propertyInfo.attributes.bath}</span>
              <span className="attribute-icon"> <FontAwesomeIcon icon={faCar} /></span>
              <span className="attribute">{propertyInfo.attributes.car}</span>
              <span>{propertyInfo.type}</span>
            </div>
          </div>
        )}
        </Draggable>
    )
}