import React from 'react';

export const SidebarData = [
    {
        name: "Home",
        path: "/"
    },
    {
        name: "Users",
        path: "/admin/usermanagement"
    },
    {
        name: "Help",
        path: "/admin/help"
    }
]