import { initializeApp } from "firebase/app";
import { onAuthStateChanged, getAuth, signOut } from "firebase/auth";
import { useEffect, useState } from "react";

const firebaseConfig = {
    apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
    authDomain: "propertydash-381e9.firebaseapp.com",
    projectId: "propertydash-381e9",
    storageBucket: "propertydash-381e9.appspot.com",
    messagingSenderId: "275281356817",
    appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
    measurementId: "G-T5KBRNMD8K"
  };
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const AuthDetails = () => {
    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {
         const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(null);
            }
         });

         return () => {
            listen();
         }
    }, []);
    
    const userSignOut = () => {
        signOut(auth).then(() => {
        }).catch(error => console.log(error));
    }

    return (
        <div>
            {authUser ? <><p>{`Signed In as ${authUser.email}`}</p> <button onClick={userSignOut}>Sign Out</button></>: <p>Signed Out</p>}
        </div>
    );
}

export default AuthDetails;