import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore/lite';
import {v4 as uuidv4} from 'uuid';
import {sendSignInLinkToEmail, getAuth} from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
    authDomain: "propertydash-381e9.firebaseapp.com",
    projectId: "propertydash-381e9",
    storageBucket: "propertydash-381e9.appspot.com",
    messagingSenderId: "275281356817",
    appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
    measurementId: "G-T5KBRNMD8K"
  };

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://localhost:3000/verifyemail',
    // This must be true.
    handleCodeInApp: true
  };
  
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);
  const auth = getAuth(app);

function NewUserModal() {
  const [show, setShow] = useState(false);
  const [isMaxUsers, setIsMaxUsers] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let currentUser = null;

  const handleSubmit = async () => {
    const email = document.getElementById("id-email").value;
    const firstName = document.getElementById("id-firstname").value;
    const lastName = document.getElementById("id-lastname").value;

    let dbUsers = null;
    currentUser = getAuth().currentUser;

    const usersRef = doc(db, "users", currentUser.email);
    getDoc(usersRef).then((res) => {
      dbUsers = res.data().users;

      if (dbUsers < 1)
        return;

      let customerId = dbUsers[0].customerId;
      const customerInfoRef = doc(db, "customerInfo", customerId);
      getDoc(customerInfoRef).then((res) => {
        let maxUsers = res.data().maxUsers - 1;

        const customerUsersRef = doc(db, "customerUsers", customerId);
            getDoc(customerUsersRef)
              .then((res) => {
                let userList = res.data().userList;

                if (userList.length >= maxUsers) {
                  setIsMaxUsers(true);
                  return;
                }

                const customerUser = {
                  email: email,
                  firstname: firstName,
                  lastname: lastName,
                  isActive: true,
                  role: "user"
                };

                userList = res.data().userList;
                const newUserList = [...userList, customerUser];
                setDoc(doc(db, "customerUsers", customerId), {
                  userList: newUserList
                })
                  .then(() => {
                    sendSignInLinkToEmail(auth, email, actionCodeSettings)
                    .then ((res) => {
                      window.localStorage.setItem('emailForSignIn', email);

                      const user = {
                        customerId: customerId,
                        email: email,
                        firstname: firstName,
                        lastname: lastName,
                        role: "user",
                        isActive: true
                      };
              
                      setDoc(doc(db, "users", email), {
                        users: [
                            {
                                user
                            }
                        ]
                      }).then((res) => {
                        handleClose();
                      })
                      .catch((err) => {});  
                    })
                    .catch((err) => {console.log(err)});
                    });
              });

        
      });
               
    })
    .catch((err) => {});
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        New User
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <table className='modal-table'>
                <tr>
                    <td className='td-input-label'>Email</td>
                    <td className='td-input-field'><input id="id-email" className='form-control input-email' type="text"/></td>
                </tr>
                <tr>
                    <td className='td-input-label'>First Name</td>
                    <td className='td-input-field'><input id="id-firstname" className='form-control input-name' type="text"/></td>
                </tr>
                <tr>
                    <td className='td-input-label'>Last Name</td>
                    <td className='td-input-field'><input id="id-lastname" className='form-control input-name' type="text"/></td>
                </tr>
            </table>
            {isMaxUsers && <p className='text-red-600'>You have reached the maximum number of users for your plan. Please contact admin@propertydash.co to upgrade.</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>Add</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewUserModal;