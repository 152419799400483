import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore/lite';
import {v4 as uuidv4} from 'uuid';
import {createUserWithEmailAndPassword, getAuth} from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
    authDomain: "propertydash-381e9.firebaseapp.com",
    projectId: "propertydash-381e9",
    storageBucket: "propertydash-381e9.appspot.com",
    messagingSenderId: "275281356817",
    appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
    measurementId: "G-T5KBRNMD8K"
  };
  
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);
  const auth = getAuth(app);
  
  const docRef = doc(db, "users", "01ac1b11-022f-4043-8d58-0f34f75b6d8c");
  const docSnap = await getDoc(docRef);
  var users = null;
  if (docSnap.exists()) {
    users = docSnap.data().users;
  } 
  

function EditUserModal({email}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var user = null;
  if (docSnap.exists()) {
    users = docSnap.data().users;
    const userIndex = users.findIndex((u) => u.email === email);
    user = users[userIndex];
  }

  const [userToEdit, setUserToEdit] = useState(user);

  const handleInputChange = async (event) => {
    setUserToEdit(userToEdit);
  }

  const handleSubmit = async () => {
    const firstName = document.getElementById("id-firstname").value;
    const lastName = document.getElementById("id-lastname").value;

    const user = {
      email: email,
      firstname: firstName,
      lastname: lastName
    };

    // Edit user in database
    var users = null;
    if (docSnap.exists()) {
      users = docSnap.data().users;
      const userIndex = users.findIndex((u) => u.email === email);
      if (userIndex >= 0) {
        users[userIndex] = user;
      }
    }

    const newDoc = {users: users};

    await setDoc(doc(db, "users", "01ac1b11-022f-4043-8d58-0f34f75b6d8c"), newDoc); 
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Edit
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <table className='modal-table'>
                <tr>
                    <td className='td-input-label'>First Name</td>
                    <td className='td-input-field'><input id="id-firstname" className='form-control input-name' type="text" value = {user.firstname}/></td>
                </tr>
                <tr>
                    <td className='td-input-label'>Last Name</td>
                    <td className='td-input-field'><input 
                                                      id="id-lastname" 
                                                      className='form-control input-name' 
                                                      name = "lastname"
                                                      type="text" 
                                                      value = {user.lastname}
                                                      onChange={handleInputChange}/></td>
                </tr>
            </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>Update</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditUserModal;