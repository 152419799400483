import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore/lite';
import NewUserModal from "../NewUserModal";
import AdminSubheading from "./Subheading";
import Button from 'react-bootstrap/Button';
import EditUserModal from "../EditUserModal";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
    authDomain: "propertydash-381e9.firebaseapp.com",
    projectId: "propertydash-381e9",
    storageBucket: "propertydash-381e9.appspot.com",
    messagingSenderId: "275281356817",
    appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
    measurementId: "G-T5KBRNMD8K"
  };
  
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

function Users() {
  const user = getAuth().currentUser;

  const usersRef = doc(db, "users", user.email);
    getDoc(usersRef).then((res) =>{
      const users = res.data().users;
      if (users.length > 0) {
        const customerId = users[0].customerId;
        const userListRef = doc(db, "customerUsers", customerId);
        getDoc(userListRef).then((res) => {
          const dbUsers = res.data().userList;
          setUserList(dbUsers);
        })
        .catch((err) => {});
      }
    })
    .catch((err) => {});

    const perUserCost = 3.99;

    const [userList, setUserList] = useState();
    const [cost, setCost] = useState(perUserCost);

    /*const handleDelete = (email) => {
      const newUserList = users.filter(l => l.email != email);
      const userRecords = {
        users: [...newUserList]
      }
      setDoc(doc(db, 'users', users[0].customerId), userRecords);

      const newCustUserList = dbCustUsers.filter(l => l.email != email);
      const custUserRecords = {
        userList: [...newCustUserList]
      }

      setUserList([newCustUserList]);
      setCost((newCustUserList.length + 1) * perUserCost);
    }*/

    const columns = [
        {
            name: "Email",
            selector: row => row.email,
            sortable: true
        },
        {
            name: "First Name",
            selector: row => row.firstname,
        },
        {
            name: "Last Name",
            selector: row => row.lastname,
        },
        {
          name: "Actions",
          selector: row => row.email,
          cell: row => (<div>
                          <span className="delete-update-record">
                            {/*<Button variant="danger" onClick={() => handleDelete(row.email)}>Delete</Button>*/}
                          </span> 
                          <span className="delete-update-record">
                            {/*<Button variant="primary" onClick={() => handleDelete(row.email)}>Edit</Button>*/}
                            {/*<EditUserModal email = {row.email} />*/}
                          </span> 
                        </div>)
        }
    ];

    return (
        <>
          <AdminSubheading name="Users" modal={<NewUserModal/>} />
          <DataTable
            columns={columns}
            data={userList}
            pagination={true}
          >
          </DataTable>   
        </>
    );
}

export default Users;