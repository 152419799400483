import React from 'react'

function AdminSubheading({name, modal}) {
  return (
    <div className="admin-subheading">
            <div className="admin-subheading-left">
                <h3 className="admin-left-label">{name}</h3>
                {modal}
        </div>
    </div>
  )
}

export default AdminSubheading