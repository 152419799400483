import React from 'react'
import Plan from './Components/Plan';
import SignupHeader from './Components/SignupHeader';

function Plans() {
  return (
    <div className='p-4'>
        <SignupHeader/>
        <h1 className='pb-4 text-3xl font-semibold'>Select your plan</h1>
        <div className='grid grid-cols-3 gap-4'>
            <Plan name={"Basic"} noOfUsers={5} price={9.99}/>
            <Plan name={"Standard"} noOfUsers={20} price={19.99}/>
            <Plan name={"Premium"} noOfUsers={50} price={39.99}/>
        </div>
    </div>
  );
}

export default Plans