import Sidebar from "./Components/Admin/Sidebar";
import SignOut from "./Components/auth/SignOut";
import NewPropertyModal from "./Components/NewPropertyModal";

function TopNavAdmin () {
    return (
        <div className="flex p-2 items-center justify-between bg-sky-400">
            <div className="flex items-center">
                <Sidebar />
                <img className="h-8 w-8" src="/propertydash_logo.png"/>
                <div className="nav-label"><h3 className="nav-name text-white"><b>PropertyDash</b></h3></div>
                <NewPropertyModal className="new-property-button"/>
            </div>
            <SignOut/>
        </div>
    )
}

export default TopNavAdmin;