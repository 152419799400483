import { initializeApp } from 'firebase/app';
import {signInWithEmailAndPassword, getAuth} from 'firebase/auth';          
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
    apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
    authDomain: "propertydash-381e9.firebaseapp.com",
    projectId: "propertydash-381e9",
    storageBucket: "propertydash-381e9.appspot.com",
    messagingSenderId: "275281356817",
    appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
    measurementId: "G-T5KBRNMD8K"
  };
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function SignOut() {
    const navigate = useNavigate();

    const userSignOut = () => {
        auth.signOut().then(function() {
            navigate('/signin');
          }, function(error) {
            
          });
    }

    return (
        <>
            <Button variant='secondary' onClick={userSignOut}>SignOut</Button>
        </>
    );
}

export default SignOut;