import TopNavAdmin from "./TopNavAdmin";
import Board from "./Board";

function Home() {
    return (
        <>
            <TopNavAdmin/>
            <Board />
        </>
    );
}

export default Home;