import React, {useEffect, useState} from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth} from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore/lite';
import { useParams } from "react-router-dom";

const firebaseConfig = {
    apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
    authDomain: "propertydash-381e9.firebaseapp.com",
    projectId: "propertydash-381e9",
    storageBucket: "propertydash-381e9.appspot.com",
    messagingSenderId: "275281356817",
    appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
    measurementId: "G-T5KBRNMD8K"
  };
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const PaymentResult = () => {
    const navigate = useNavigate();
    const routeParams = useParams();
    const email = routeParams.email;
    const plan = routeParams.plan;

    useEffect(() => {
        const usersRef = doc(db, "users", email);
        getDoc(usersRef).then((res) =>{
          const users = res.data().users;
          if (users.length > 0) {
            users[0].isActive = true;
            let customerId = users[0].customerId;
            let custInfo = {
              plan: plan
            };
            setDoc(doc(db, 'customerInfo', customerId), custInfo)
              .then((res) => {
                setDoc(doc(db, 'users', email), users)
              .then((res) => {}).catch ((error) => {})
              }).catch ((error) => {})
          }
        })
        .catch((err) => {});
      }, []);

    return (
        <div className='signin-form'>
            <h5>Thank you for signing up with PropertyDash!</h5>
            <br/>
            <Button href="/signin">Sign In</Button>
        </div>
    );
}

export default PaymentResult;
