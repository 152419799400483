import React from 'react'
import TopNavAdmin from '../../TopNavAdmin'
import AdminSubheading from './Subheading'
import Contact from './Contact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { Icon } from '@mui/material'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

function Help() {
  return (
    <div> 
        <TopNavAdmin />
        <AdminSubheading name="Help" />
       
        <div className='grid grid-cols-1'>
         <Contact desc="help@propetydash.co">
          <FontAwesomeIcon className='fa-2xl' icon={faEnvelope} />
         </Contact>
         <Contact desc="+61401053231">
          <FontAwesomeIcon className='fa-2xl' icon={faPhone} />
         </Contact>
        </div>
    </div>
  )
}

export default Help