import React from 'react'

function SignupHeader() {
  return (
    <div className='flex pb-4'>
        <img className="h-8 w-8" src="/propertydash_logo.png"/>
        <span className='px-2 text-xl font-semibold'>PropertyDash</span>
    </div>
  )
}

export default SignupHeader