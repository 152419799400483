import React from "react";
import { Navigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { onAuthStateChanged, getAuth, signOut } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
    authDomain: "propertydash-381e9.firebaseapp.com",
    projectId: "propertydash-381e9",
    storageBucket: "propertydash-381e9.appspot.com",
    messagingSenderId: "275281356817",
    appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
    measurementId: "G-T5KBRNMD8K"
  };
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const PrivateRoute = ({isAllowed, children}) => {
     return (isAllowed ? children : <Navigate to="/signin" />);
};

export default PrivateRoute;