import { initializeApp } from 'firebase/app';
import { sendPasswordResetEmail, getAuth} from 'firebase/auth';
import React, {useState} from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
    apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
    authDomain: "propertydash-381e9.firebaseapp.com",
    projectId: "propertydash-381e9",
    storageBucket: "propertydash-381e9.appspot.com",
    messagingSenderId: "275281356817",
    appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
    measurementId: "G-T5KBRNMD8K"
  };
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const auth = getAuth();

    function onEmailUpdate(e) {
        setEmail(e.target.value);
    }

    const triggerResetEmail = (e) => {
        e.preventDefault();

        sendPasswordResetEmail(auth, email)
            .then((res) => {
                navigate("/signin");
            })
            .catch((e) => {
                navigate("/signin");
            }); 
      }

    return (
        <div className='signin-form'>
            <div>
                <form onSubmit={triggerResetEmail}>
                    <h3>Reset Password</h3>
                    <input type="email" id="id-email" placeholder='Email address' className='form-control signin-field' onChange={onEmailUpdate}></input> <br/>
                    <Button type="submit" variant='primary'>Reset</Button>
                </form>
            </div>
            <br />
            <div>
                <p>Back to <a href="/signin">Sign In</a></p>
            </div>
        </div>
    );
}

export default ForgotPassword;
