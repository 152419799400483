import { Droppable } from "react-beautiful-dnd";
import Property from "./Property";

function Stage({store, key, onClicked}) {
  let displayPropertyList = store.propertyList;
  if (store.stageName == "Completed") {
    let filterDate = new Date();
    filterDate.setDate(filterDate.getDate() - 14);
    displayPropertyList = displayPropertyList.filter((prop) => prop.lastStateChanged > filterDate.toISOString());
  }
  return (
    <Droppable droppableId={store.id}>
      {(provided) => (
        <div className="stage" {...provided.droppableProps} ref={provided.innerRef}>
          <h5>{store.stageName}</h5>
            {displayPropertyList.map((property, index) => (
              <Property id={property.propertyId} propertyInfo = {property} index={index} onClicked={onClicked}/>
            ))}
            {provided.placeholder}
        </div>
      )}
    </Droppable>)
}

export default Stage;