import React from 'react'
import { useNavigate } from 'react-router-dom';

function Plan({name, noOfUsers, price}) {
  function handleSelect() {
    navigate(`/signup/${name}`);
  }

  const navigate = useNavigate();
  return (
    <div>
        <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
            <div className='text-center'>
                <h1 className='font-semibold'>{name}</h1>
                <p>Up to {noOfUsers} users</p>
                <p>${price} per month</p>
                <p>14 day free trial. Cancel anytime.</p>
                <button 
                    class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleSelect}
                >
                    Select
                </button>
            </div>
        </div>
       
    </div>
  )
}

export default Plan