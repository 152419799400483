import { initializeApp } from 'firebase/app';
import {createUserWithEmailAndPassword, getAuth} from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore/lite';
import React, {useState} from 'react';
import { Button } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import SignupHeader from '../SignupHeader';

const firebaseConfig = {
    apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
    authDomain: "propertydash-381e9.firebaseapp.com",
    projectId: "propertydash-381e9",
    storageBucket: "propertydash-381e9.appspot.com",
    messagingSenderId: "275281356817",
    appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
    measurementId: "G-T5KBRNMD8K"
  };
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const stages = { 
    stageList :[
        {
            id: "1",
            propertyList: [],
            stageName: "New Business"
        },
        {
            id: "2",
            propertyList: [],
            stageName: "Re-lease"
        },
        {
            id: "3",
            propertyList: [],
            stageName: "Advertising"
        },
        {
            id: "4",
            propertyList: [],
            stageName: "Completed"
        }
    ]};

const docRef = doc(db, "plans", "main");
const docSnap = await getDoc(docRef);
var plans = null;
if (docSnap.exists()) {
  plans = docSnap.data().plans;
} 

const SignUp = () => {
    const { plan } = useParams();
    const navigate = useNavigate();

    const  signUp = (e) => {
        e.preventDefault();

        const email = document.getElementById("id-email").value;
        const password = document.getElementById("id-password").value;
        const firstName = document.getElementById("id-firstname").value;
        const lastName = document.getElementById("id-lastname").value;

        var customerId = uuid();
        setDoc(doc(db, "users", email), {
            users: [
                {
                    customerId: customerId,
                    email: email,
                    firstname: firstName,
                    lastname: lastName,
                    role: "admin",
                    isActive: false
                }
            ]
        }).then((res) => { 
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    setDoc(doc(db, 'customerProperties', customerId), stages)
                        .then((res) => {
                            navigate(`/signup/creditcard/${email}/${plan}`);
                        }).catch ((error) => {})
                    
            }).catch ((error) => {
                console.warn(error);
            })

        }).catch((err) => {});;
    }

    return (
        <div className='p-4'>
        <SignupHeader/>
        <div className='flex justify-center'>
            <form onSubmit={signUp}>
                <h3 className='pb-4 text-2xl font-semibold'>You've selected the {plan} plan</h3>
                <h5 className='pb-4 font-semibold'>Enter your details below</h5>
                <div className='pb-4 grid grid-cols-2 gap-4'>
                    <div>Email:</div>
                    <div><input type="email" id="id-email" placeholder='Your email' className='form-control signin-field'></input></div>
                
                    <div>First Name:</div>
                    <div><input type="text" id="id-firstname" placeholder='Your first name' className='form-control signin-field'></input></div>

                    <div>Last Name:</div>
                    <div><input type="text" id="id-lastname" placeholder='Your last name' className='form-control signin-field'></input></div>

                    <div>Password:</div>
                    <div><input type="password" id="id-password" className='form-control signin-field'></input></div>
                </div>
                
                <Button type="submit" variant='primary'>Sign Up</Button>

                <div className='py-4'>Already have an account? <a href='/signin'>Sign In</a>.</div>
            </form>
            
        </div>
        </div>
    );
}

export default SignUp;
