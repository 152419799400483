import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore/lite';
import {v4 as uuidv4} from 'uuid';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
    authDomain: "propertydash-381e9.firebaseapp.com",
    projectId: "propertydash-381e9",
    storageBucket: "propertydash-381e9.appspot.com",
    messagingSenderId: "275281356817",
    appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
    measurementId: "G-T5KBRNMD8K"
  };
  
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);

function NewPropertyModal() {
  const [show, setShow] = useState(false);
  const [showRequired, setShowRequired] = useState(false);
  const [customerId, setCustomerId] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user == null) {
      return;
    }

    const usersRef = doc(db, "users", user.email);
    getDoc(usersRef).then((res) =>{
      const users = res.data().users;
      if (users.length > 0) {
        setCustomerId(users[0].customerId);
      }
    })
    .catch((err) => {});
  }, []);

  function createNewProperty() {
    const address = document.getElementById("id-address").value;
    const rent = document.getElementById("id-rent").value;
    const tenanted = document.getElementById("id-tenanted").checked;
    const availableDate = document.getElementById("id-available-date").value;
    const openDate = document.getElementById("id-open-date").value;
    const bedrooms = document.getElementById("id-bedrooms").value;
    const bathrooms = document.getElementById("id-bathrooms").value;
    const carSpaces = document.getElementById("id-car-spaces").value;
    const type = document.getElementById("id-type").value;
    var currDate = new Date();

    return {
        address: address,
        tenanted: tenanted,
        availableDate: availableDate,
        openDate: openDate,
        propertyId: uuidv4(),
        type: type,
        attributes: {
            bath: bathrooms,
            bed: bedrooms,
            car: carSpaces
        },
        rent: {
            value: rent,
            type: "per week"
        },
        created: currDate.toISOString(),
        lastUpdated: currDate.toISOString(),
        lastStateChanged: currDate.toISOString()
      }
  }

  const handleSubmit = async () => {
    const strStage = document.getElementById("id-stage").value;

    const newProperty = createNewProperty();

    if (!newProperty.address) {
      setShowRequired(true);
      return;
    }

    var stages = null;
    const docRef = doc(db, "customerProperties", customerId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        stages = docSnap.data().stageList;
        const stageIndex = stages.findIndex((stage) => stage.stageName === strStage);
        const properties = [...stages[stageIndex].propertyList];

        properties.push(newProperty);

        const newStages = [...stages];
        newStages[stageIndex] = {
                ...stages[stageIndex],
            propertyList: properties
        }

        const newDoc = {stageList: newStages};

        await setDoc(doc(db, "customerProperties", customerId ), newDoc);    
    } 
    setShow(false);

    window.location.reload();
  }

  function SelectBedrooms() {
    return (
        <select class="form-select select-rooms" name="bedrooms" id="id-bedrooms">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
        </select>
    );
  }

  function SelectBathrooms() {
    return (
        <select class="form-select select-rooms" name="bathrooms" id="id-bathrooms">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
        </select>
    );
  }

  function SelectCarSpaces() {
    return (
        <select class="form-select select-rooms" name="car-spaces" id="id-car-spaces">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
        </select>
    );
  }

  function SelectType() {
    return (
        <select class="form-select" name="type" id="id-type">
            <option value="House">House</option>
            <option value="Apartment">Apartment</option>
            <option value="Townhouse">Townhouse</option>
            <option value="Villa">Villa</option>
        </select>
    );
  }

  function SelectStage() {
    return (
        <select class="form-select" name="stage" id="id-stage">
            <option value="New Business">New Business</option>
            <option value="Re-lease">Re-lease</option>
            <option value="Advertising">Advertising</option>
            <option value="Completed">Completed</option>
        </select>
    );
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        New Property
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <table className='modal-table'>
                <tr>
                    <td className='td-input-label'>Address</td>
                    <td className='td-input-field'>
                      <input id="id-address" className='form-control' type="text"/>
                      {showRequired && (<span className='text-red-600'>This field is required</span>)}
                    </td>
                </tr>
                <tr>
                    <td className='td-input-label'>Rent Per Week  ($)</td>
                    <td className='td-input-field'><input id="id-rent" className='form-control input-rent' type="text"/></td>
                </tr>
                <tr>
                    <td className='td-input-label'>Tenanted</td>
                    <td className='td-input-field'><input id="id-tenanted" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"/></td>
                </tr>
                <tr>
                    <td className='td-input-label'>Available Date</td>
                    <td className='td-input-field'><input id="id-available-date" className='form-control input-date' type="date"/></td>
                </tr>
                <tr>
                    <td className='td-input-label'>Open Date</td>
                    <td className='td-input-field'><input id="id-open-date" className='form-control input-date' type="date"/></td>
                </tr>
                <tr>
                    <td className='td-input-label'>Bedrooms</td>
                    <td className='td-input-field'><SelectBedrooms/></td>
                    
                </tr>
                <tr>
                    <td className='td-input-label'>Bathrooms</td>
                    <td className='td-input-field'><SelectBathrooms/></td>
                </tr>
                <tr>
                    <td className='td-input-label'>Car Spaces</td>
                    <td className='td-input-field'><SelectCarSpaces/></td>
                </tr>
                <tr>
                    <td className='td-input-label'>Type</td>
                    <td className='td-input-field'><SelectType/></td>
                </tr>
                <tr>
                    <td className='td-input-label'>Stage</td>
                    <td className='td-input-field'><SelectStage/></td>
                </tr>
            </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>Add</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewPropertyModal;