import TopNavAdmin from "../../TopNavAdmin";
import Users from "./Users"

function UserManagement() {
    return (
        <>
        <TopNavAdmin />
        <Users />
        </>
    );
}

export default UserManagement;