import React, { useState } from 'react';
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SidebarData } from './SidebarData';

function Sidebar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
        <div className='sidebar-icon pr-2'>
            <Link to="#">
                <FontAwesomeIcon icon={faBars} size="lg" onClick={showSidebar} inverse/>
            </Link>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-items' onClick={showSidebar}>
                <li className='navbar-toggle'>
                    <Link to="#" className='menu-bars'>
                    <FontAwesomeIcon icon={faXmark} size="lg"/>
                    </Link>
                </li>
                {SidebarData.map((item, index)=> {
                    return (
                        <li key={index} className='nav-text'>
                            <Link to={item.path}>
                                {item.name}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </nav>
  </>
  );
}

export default Sidebar