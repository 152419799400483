import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./CreditCard.css";
import { useParams } from "react-router-dom";
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore/lite';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
  authDomain: "propertydash-381e9.firebaseapp.com",
  projectId: "propertydash-381e9",
  storageBucket: "propertydash-381e9.appspot.com",
  messagingSenderId: "275281356817",
  appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
  measurementId: "G-T5KBRNMD8K"
};

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51NleWTJvNmfkDIPzvdJ41puC1oilfKyAdzSRc3qgcMqTF5SonZKrg000U9BgzeCCy5RlFQ7lg94ucOroUkoKJBem00yUwWpSvt");

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export default function CreditCard() {
  const [clientSecret, setClientSecret] = useState("");

  const routeParams = useParams();

  useEffect(() => {
     // Create PaymentIntent as soon as the page loads
    fetch("https://createsubscription-6sh4js7rea-uc.a.run.app", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: routeParams.email}),
    })
      .then((res) => res.json())
      .then((data) => {
        const subscriptionId = data.subscriptionId;
        const usersDocRef = doc(db, "users", routeParams.email);
        getDoc(usersDocRef)
          .then((res) => {
            const users = res.data().users;
            const customerId = users[0].customerId;
            setDoc(doc(db, "subscription", customerId), {
              subscriptionId: subscriptionId
            }).then((res) => {
              setClientSecret(data.clientSecret);
              });
          });
      });
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="signin-form">
      
      { clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm email={routeParams.email} plan={routeParams.plan}/>
        </Elements>
      )}
    </div>
  );
}