import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Stage from './Stage';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore/lite';
import { getAuth } from "firebase/auth";
import NewPropertyModal from './Components/NewPropertyModal';
import EditPropertyModal from './Components/EditPropertyModal';

const firebaseConfig = {
  apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
  authDomain: "propertydash-381e9.firebaseapp.com",
  projectId: "propertydash-381e9",
  storageBucket: "propertydash-381e9.appspot.com",
  messagingSenderId: "275281356817",
  appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
  measurementId: "G-T5KBRNMD8K"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

var customerId = null;
var properties = null;

function App() {
  const [stageProperties, setStageProperties] = useState(properties);
  const [showModal, setShowModal] = useState(false); 
  const [stageIndex, setStageIndex] = useState(0);
  const [modalProperty, setModalProperty] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user == null) {
      return;
    }

    const usersRef = doc(db, "users", user.email);
    getDoc(usersRef).then((res) =>{
      const users = res.data().users;
      if (users.length > 0) {
        customerId = users[0].customerId;
        const docRef = doc(db, "customerProperties", customerId);
        getDoc(docRef).then((res) => {
          properties = res.data().stageList;
          setStageProperties(properties);
        })
        .catch((err) => {});
      }
    })
    .catch((err) => {});
  }, []);
  
  const handleDragDrop = (results) => {
    const {source, destination, type} = results;

    if (!destination)
      return;

    if (source.droppableId === destination.droppableId && source.index === destination.index)
      return;

    const storeSourceIndex = stageProperties.findIndex((stage) => stage.id === source.droppableId);

    const storeDestinationIndex = stageProperties.findIndex((stage) => stage.id === destination.droppableId);

    const newSourceItems = [...stageProperties[storeSourceIndex].propertyList];
    const newDestinationItems = 
                      source.droppableId !== destination.droppableId ?
                      [...stageProperties[storeDestinationIndex].propertyList] : newSourceItems;

    const [deletedItem] = newSourceItems.splice(source.index, 1);
    deletedItem.lastStateChanged = new Date().toISOString();
    newDestinationItems.splice(destination.index, 0, deletedItem);

    const newStageProperties = [...stageProperties];
    newStageProperties[storeSourceIndex] = {
      ...stageProperties[storeSourceIndex],
      propertyList: newSourceItems
    }
    
    newStageProperties[storeDestinationIndex] = {
      ...stageProperties[storeDestinationIndex],
      propertyList: newDestinationItems
    }

    setStageProperties(newStageProperties);

    const customerProperties = {
      stageList: [...newStageProperties]
    }
    setDoc(doc(db, 'customerProperties', customerId), customerProperties);
  }

  const showEditDialog = ((stageIndex, propertyId) => {
    let stage = properties[stageIndex];
    let property = stage.propertyList.find((p) => p.propertyId == propertyId);
    if (property) {
      setStageIndex(stageIndex);
      setModalProperty(property);
      setShowModal(true);
    }
  });

  return (
    <div className="layout__wrapper">
      <div className='board'>
        {showModal && <EditPropertyModal stageIndex={stageIndex} property={modalProperty} handleClose={() => {setShowModal(false)}}/>}
        <DragDropContext onDragEnd={handleDragDrop}>
          {stageProperties && stageProperties.map((store, index) => (
            <Stage store={store} onClicked={((propertyId) => {showEditDialog(index, propertyId)})}/>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
}

export default App;
