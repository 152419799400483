import { getAuth, isSignInWithEmailLink, signInWithEmailLink, updatePassword } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
    const auth = getAuth();
    const navigate = useNavigate();

    const onSubmit = () => {
        const email = document.getElementById("id-email").value;
        const password = document.getElementById("id-password").value;

        signInWithEmailLink(auth, email, window.location.href)
        .then(() => {
            updatePassword(auth.currentUser, password)
            .then(() => {
                navigate("/");
            });
        });
    }

    return (
        <>
            {
                <div>
                    <div className="mx-auto max-w-xs m-4 grid grid-cols-2 gap-2">
                        <h1 className="col-span-2 text-2xl font-semibold">Please select a password.</h1>
                        <div className="font-semibold">Email</div>
                        <div><input id="id-email" className="border rounded-s" type="text" size="30" /></div>
                        <div className="font-semibold">Password</div>
                        <div><input id="id-password" className="border rounded-s" type="password" size="30" /></div>
                        <div><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={onSubmit}>Submit</button></div>
                    </div>
                </div>
            }
        </>
    );
}

export default VerifyEmail;