import { initializeApp } from 'firebase/app';
import {signInWithEmailAndPassword, getAuth} from 'firebase/auth';
import React, {useState} from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
    apiKey: "AIzaSyDkfqwjPX6QW2F36WqojufvBoz4DPeM8_g",
    authDomain: "propertydash-381e9.firebaseapp.com",
    projectId: "propertydash-381e9",
    storageBucket: "propertydash-381e9.appspot.com",
    messagingSenderId: "275281356817",
    appId: "1:275281356817:web:7a7f2fc6bf76f94d2aa9b0",
    measurementId: "G-T5KBRNMD8K"
  };
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const SignIn = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));

    const  signIn = (e) => {
        e.preventDefault();

        const email = document.getElementById("id-email").value;
        const password = document.getElementById("id-password").value;

        signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    navigate("/");
                    navigate(0);
        }).catch ((error) => {
        })
    }

    return (
        <div className='signin-form'>
            <div>
                <form onSubmit={signIn}>
                    <h3>Sign In</h3>
                    <input type="email" id="id-email" placeholder='Sign with  your  email' className='form-control signin-field'></input> <br/>
                    <input type="password" id="id-password" className='form-control signin-field'></input><br/>
                    <Button type="submit" variant='primary'>Sign In</Button>
                </form>
            </div>
            <br/>
            <div>
                <p><a href="/forgotpassword">Forgot password</a></p>
            </div>
            <div>
                <p>No account yet? <a href="/signup">Create one</a></p>
            </div>
        </div>
    );
}

export default SignIn;
